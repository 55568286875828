import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0656432c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-10 flex justify-center" }
const _hoisted_2 = { class: "flex flex-col md:flex-row items-stretch space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_NFTGrid = _resolveComponent("NFTGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "is-full-page": _ctx.fullPage,
      loader: _ctx.loader
    }, null, 8, ["active", "is-full-page", "loader"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.initialVaultNFTs.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "mr-5 bg-red-700 hover:bg-red-700 text-white font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.withdrawAllNFTOnChain && _ctx.withdrawAllNFTOnChain(...args)))
          }, " Unstake All "))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NFTGrid, {
        title: "Your wallet",
        class: "flex-1",
        nfts: _ctx.initialWalletNFTs,
        selectedNFT: _ctx.selectedNFT,
        staked: false,
        onSelected: _ctx.depositNFTOnChain
      }, null, 8, ["nfts", "selectedNFT", "onSelected"]),
      (_ctx.bank && _ctx.vault)
        ? (_openBlock(), _createBlock(_component_NFTGrid, {
            key: 0,
            title: "Staked DeGods",
            class: "flex-1",
            nfts: _ctx.initialVaultNFTs,
            selectedNFT: _ctx.selectedNFT,
            staked: true,
            onSelected: _ctx.withdrawNFTOnChain
          }, null, 8, ["nfts", "selectedNFT", "onSelected"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}